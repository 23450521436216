<template>
  <v-container fluid class="pa-0 ma-0 ">
    <v-card
      flat
      tile
      min-height="100vh"
      v-if="localSeeScore"
      fluid
      class="primarygrad pb-15 pt-5"
    >
      <div class="d-flex align-center justify-center">
        <v-switch
          v-model="localSeeScore"
          class="mt-5"
          inset
          label="Turn Off"
          color="error"
          @change="ScoreVisible"
        ></v-switch>
      </div>

      <v-tabs v-model="tabs" dark background-color="transparent" centered>
        <v-tabs-slider color="transparent"></v-tabs-slider>
        <v-tab href="#topCaller">Top Caller</v-tab>
        <v-tab href="#topActive"> Most Active</v-tab>
      </v-tabs>
      <v-card
        flat
        width="100%"
        color="transparent"
        class="ma-md-5 pa-md-5 rounded"
      >
        <v-select
          v-model="time"
          class="mx-15 my-5 my-md-0"
          :items="timeoptions"
          solo
          flat
          label="Time"
          dense
          @change="onDateChange"
        />

        <v-tabs-items v-model="tabs" class="transparent">
          <v-tab-item value="topCaller" class="transparent">
            <Leader :tabtype="tabs" :accounts="finalData" />
          </v-tab-item>
          <v-tab-item value="topActive" class="transparent">
            <Leader :tabtype="tabs" :accounts="finalData" />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-card>
    <v-container v-else fluid class="fill-height">
      <v-card width="100%" height="100vh" flat>
        <v-img
          contain
          height="50%"
          :src="require('../assets/noscore.png')"
        ></v-img>
        <h1 class="text-center font-weight-medium mt-5">
          The Leaderboard is turned off
        </h1>
        <div class="d-flex align-center justify-center">
          <v-switch
            v-model="localSeeScore"
            class="mt-5"
            inset
            label="Turn On"
            @change="ScoreVisible"
          ></v-switch>
        </div>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import Leader from "../components/leader.vue";
import Vue from "vue";
import VueConfetti from "vue-confetti";
import { mapGetters } from "vuex";
const _ = require("lodash");
import moment from "moment-timezone";

Vue.use(VueConfetti);

export default {
  components: { Leader },
  data() {
    return {
      tabs: "topCaller",
      accounts: [],
      time: "Daily",
      timeoptions: ["Daily", "Weekly", "Monthly"],
      dateRange: [],
      callerActivity: [],
      finalData: [],
      localSeeScore: false,
    };
  },
  computed: {
    ...mapGetters(["dispoList", "selectedTeam", "teams", "ENDPOINT", "user"]),
    seeScore() {
      return this.$store.getters.user.seeScore;
    },
  },
  watch: {
    localSeeScore(newVal) {
      if (newVal) {
        this.initSet();
      }
    },
  },
  created() {
    this.localSeeScore = this.seeScore;
    if (this.localSeeScore) {
      this.initSet();
    }
  },
  methods: {
    ScoreVisible() {
      var payload = {};
      payload.seeScore = this.localSeeScore;
      this.$http
        .patch(
          this.$store.state.ENDPOINT + "/accounts/" + this.$store.state.user.id,
          payload
        )
        .then(() => {
          var user = this.$store.getters.user;
          user.seeScore = this.localSeeScore;
          this.$store.commit("setUser", user);
        })
        .catch(() => {
          console.log("Error in patching");
        });
    },
    async initSet() {
      this.setDates();
      await this.getAccountsByTeam();
      await this.getAccountId();
      this.start();
      setTimeout(this.stop, 3000);
    },
    start() {
      this.$confetti.start();
    },

    stop() {
      this.$confetti.stop();
    },
    async getAccountsByTeam() {
      try {
        const self = this;
        let teamIds = [self.selectedTeam];

        if (self.selectedTeam == "allTeams") {
          teamIds = _.map(
            _.filter(self.teams, (t) => {
              return t.id != "allTeams";
            }),
            "id"
          );
        }

        let accountsRespo = await self.$http.post(
          `${self.ENDPOINT}/accounts/fetch`,
          {
            teams: teamIds,
          }
        );

        self.accounts = accountsRespo.body;
      } catch (err) {
        console.log("Error: In getAccountsByTeam", err);
      }
    },
    setDates() {
      let startDate = moment().format("YYYY-MM-DD");
      let endDate = moment().format("YYYY-MM-DD");

      if (this.time == "Weekly") {
        startDate = moment()
          .subtract(7, "days")
          .format("YYYY-MM-DD");
      } else if (this.time == "Monthly") {
        startDate = moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD");
      }

      this.dateRange = [startDate, endDate];
    },
    async onDateChange() {
      await this.setDates();
      await this.getAccountId();
      this.start();
      setTimeout(this.stop, 3000);
    },
    async getAccountId() {
      try {
        this.callerActivity = await this.$http.post(
          `${this.$store.state.ENDPOINT}/logs/v2/filterByDays`,
          {
            users: _.map(this.accounts, "id"),
            dates: this.dateRange,
            dataFor: "leaderboard",
          }
        );

        this.callerActivity = this.callerActivity.body;

        this.finalData = [];
        this.accounts.forEach((a) => {
          let activity = _.find(this.callerActivity, (c) => {
            return a.id == c._id.accountsId;
          });

          // Local Account Set
          let lA = {
            id: a.id,
            name: a.name,
            role: a.role,
            picture: a.picture,
            pictureId: a.pictureId,
          };

          if (!activity) {
            lA.count = 0;
            lA.duration = 0;
            lA.timeSpentOnCall = 0;
          } else {
            lA.count = activity.count;
            lA.duration = activity.duration;
            lA.timeSpentOnCall = Math.round(
              activity.timeSpentOnCall / (1000 * 60)
            );
          }
          this.finalData.push(lA);

          let orderField = "count";
          if (this.tabs == "topActive") orderField = "timeSpentOnCall";
          this.finalData = _.orderBy(this.finalData, [orderField], ["desc"]);
        });
      } catch (e) {
        console.log("Error: Get All Account", e);
      }
    },
  },
};
</script>

<style scoped>
.theme--dark.v-tabs .v-tab--active:before,
.theme--dark.v-tabs .v-tab--active:hover:before,
.theme--dark.v-tabs .v-tab:focus:before {
  opacity: 0;
}
.v-tab {
  color: #ffffff !important;
  transition: all 0.2s ease-in-out;
  margin: 0 4px;
}
.v-tab--active {
  background-color: rgba(0, 0, 0, 0.301);
  border-radius: 25px;
}
.v-tab--active:hover {
  background-color: rgba(0, 0, 0, 0.65) !important;
  border-radius: 25px;
}
.v-tab:hover {
  background-color: rgba(0, 0, 0, 0.65);
  border-radius: 25px;
}
</style>
